<template>
  <div>
    <div class="oa-work-box border">
      <span>
        <el-button class="oa-button" size="mini" @click="add">
          <font-awesome-icon fas icon="plus"></font-awesome-icon>&nbsp;添加
        </el-button>
        <el-button class="oa-button" size="mini" @click="update">
          <font-awesome-icon fas icon="edit"></font-awesome-icon>&nbsp;修改
        </el-button>
        <el-button class="oa-button oa-button-danger" size="mini" @click="del">
          <font-awesome-icon fas icon="trash"></font-awesome-icon>&nbsp;删除
        </el-button>
        <el-button @click="exportExcel" size="small" class="oa-button">导出Excel</el-button>
      </span>
      <span>
        <el-input v-model="searchOption.key" class="search-input" size="mini" placeholder="请输入名称搜索">
        </el-input>
        <el-button type="primary" size="mini" @click="search">
          <font-awesome-icon fas icon="search"></font-awesome-icon>&nbsp;查询
        </el-button>
      </span>
    </div>
    <!-- 主体内容 -->
    <el-table border stripe :data="list" @selection-change="selectionChange" size="mini" class="oa-table">
      <el-table-column width="50" align="center" type="index" label="#"></el-table-column>
      <el-table-column type="selection" min-width="50" header-align="center" align="center">
      </el-table-column>
      <el-table-column sortable prop="Name" label="名称"></el-table-column>
      <el-table-column sortable prop="CompanyName" label="公司名称"></el-table-column>
      <el-table-column sortable prop="ECompanyName" label="公司英文名称"></el-table-column>
      <el-table-column prop="Address" label="公司地址"></el-table-column>
      <el-table-column prop="Description" label="描述"></el-table-column>
      <el-table-column sortable prop="CreateTime" label="创建日期" width="140"></el-table-column>
    </el-table>
    <el-pagination background layout="total, sizes, prev, pager, next, jumper" :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" @size-change="pageSizeChange"
      @current-change="pageIndexChange">
    </el-pagination>
  </div>
</template>

<script>
import API from '../../apis/ticket-api'
import { ORGANIZER, ORGANIZER_FORM } from '../../router/base-router'

export default {
  name: ORGANIZER.name,
  data () {
    return {
      disabled: true, // 允许编辑
      loading: false, // 加载中
      searchOption: {
        key: '' // 搜索条件（关键字）
      },
      list: [], // 列表
      total: 1, // 总数据量
      pageIndex: 1, // 页码
      pageSize: 50, // 页数
      selectionList: [] // 选中的人员列表
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.init())
  },
  methods: {
    init () {
      if (!this.loading) {
        this.loading = true
        this.get()
        this.loading = false
      }
    },
    get () {
      this.loading = true
      this.axios.get(`${API.ORGANIZER.URL}/${this.pageIndex}/${this.pageSize}`, {
        params: this.searchOption
      }).then(response => {
        this.total = response.Total
        this.list = response.Items
        this.loading = false
      })
    },
    pageSizeChange (value) {
      this.pageSize = value
      this.$nextTick(() => this.get())
    },
    pageIndexChange (value) {
      this.pageIndex = value
      this.$nextTick(() => this.get())
    },
    search () {
      this.pageIndex = 1
      this.$nextTick(() => this.get())
    },
    selectionChange (rows) {
      this.selectionList = rows
    },
    add () {
      var entity = { isAdd: true }
      this.toFormPage(entity)
    },
    update () {
      if (this.checkHadSelection()) {
        const entity = this.selectionList[0]
        this.toFormPage(entity)
      }
    },
    del () {
      if (this.checkHadSelection()) {
        this.$confirm('确认要删除选中数据?删除后不可恢复，请谨慎操作！', '温馨提示', {
          type: 'warning',
          cancelButtonText: '放弃操作'
        }).then(() => {
          const ids = this.selectionList.map(s => s.Id)
          this.axios.patch(API.ORGANIZER.BATCH_DELETE, ids).then(response => {
            if (response.Status) {
              this.get()
            }
          })
        })
      }
    },
    checkHadSelection () {
      if (this.selectionList.length < 1) {
        this.$message.error('请先选择要操作数据')
        return false
      }
      return true
    },
    moreCommand (command) {
      switch (command) {
        case 'export': this.exportExcel(); break
      }
    },
    exportExcel () {
      this.axios.get(API.ORGANIZER.EXCEL, {
        responseType: 'blob'
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob) // 创建下载的链接
        downloadElement.href = href
        downloadElement.download = '主办方.xlsx' // 下载后文件名
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
      })
    },
    toFormPage (params) {
      this.$router.push({ ...ORGANIZER_FORM, params: params })
    }
  },
  created () {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
/deep/ .oa-work-box {
  .search-input {
    width: 200px;
    margin-right: .75rem;
  }

  .type-select {
    width: 100px;
    margin-right: .75rem;
  }

}
</style>
